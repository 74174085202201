import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PagingService {
  private pageNoMap = new Map<string, BehaviorSubject<number>>();
  private pageSizeMap = new Map<string, BehaviorSubject<number>>();

  private readonly defaultPageNo = 1;
  private readonly defaultPageSize = 10;

  constructor() {}

  private getPageNoSubject(componentId: string): BehaviorSubject<number> {
    if (!this.pageNoMap.has(componentId)) {
      this.pageNoMap.set(componentId, new BehaviorSubject<number>(1));
    }
    return this.pageNoMap.get(componentId)!;
  }

  private getPageSizeSubject(componentId: string): BehaviorSubject<number> {
    if (!this.pageSizeMap.has(componentId)) {
      this.pageSizeMap.set(componentId, new BehaviorSubject<number>(10));
    }
    return this.pageSizeMap.get(componentId)!;
  }

  setPageNo(componentId: string, pageNo: number): void {
    this.getPageNoSubject(componentId).next(pageNo);
  }

  getPageNo(componentId: string): number {
    return this.getPageNoSubject(componentId).getValue();
  }

  setPageSize(componentId: string, pageSize: number): void {
    this.getPageSizeSubject(componentId).next(pageSize);
  }

  getPageSize(componentId: string): number {
    return this.getPageSizeSubject(componentId).getValue();
  }

  getPageNoObservable(componentId: string) {
    return this.getPageNoSubject(componentId).asObservable();
  }

  getPageSizeObservable(componentId: string) {
    return this.getPageSizeSubject(componentId).asObservable();
  }

  reset(componentId: string): void {
    this.setPageNo(componentId, this.defaultPageNo);
    this.setPageSize(componentId, this.defaultPageSize);
  }

  resetAll(): void {
    this.pageNoMap.forEach((_, componentId) => {
      this.reset(componentId);
    });
    this.pageSizeMap.forEach((_, componentId) => {
      this.reset(componentId);
    });
  }
}

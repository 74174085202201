import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class InactivityService {
  private timeoutId: any;
  private readonly inactivityTime = 30 * 60 * 1000;

  constructor(
    private router: Router,
    private localStorageService: LocalStorageService
  ) {
    this.startTimer();
    this.setupListeners();
  }

  private startTimer() {
    this.timeoutId = setTimeout(() => this.logout(), this.inactivityTime);
  }

  private resetTimer() {
    clearTimeout(this.timeoutId);
    this.startTimer();
  }

  private logout() {
    this.localStorageService.clear();
    this.router.navigate(['/home']);
  }

  private setupListeners() {
    const events = ['mousemove', 'mousedown', 'click', 'scroll', 'keypress'];

    events.forEach((event) => {
      document.addEventListener(event, () => this.resetTimer(), true);
    });
  }
}
